import { EmbeddedConfig } from "../../../s25-embedding-config/embedded.config.service";
import { S25Util } from "../../../../util/s25-util";

export const S25EmbeddingUtil = {
    typeList: [
        "availability",
        "availability-weekly",
        "availability-weekly-mult-loc",
        "calendar",
        "event-form",
        "express",
        "details-list",
    ],
    types: {
        availability: {
            name: "Availability Daily",
            mode: "either",
            canCreateEvent: true,
            canLogin: false,
            itemTypes: [
                { name: "Locations", id: 4 },
                { name: "Resources", id: 6 },
            ],
        },
        "availability-weekly": {
            name: "Availability Weekly",
            mode: "item",
            canCreateEvent: true,
            canLogin: false,
            itemTypes: [{ name: "Locations", id: 4 }],
        },
        "availability-weekly-mult-loc": {
            name: "Availability Weekly Multi-Location",
            mode: "query",
            canCreateEvent: true,
            canLogin: false,
            itemTypes: [{ name: "Locations", id: 4 }],
        },
        calendar: {
            name: "Calendar",
            mode: "either",
            canCreateEvent: true,
            canLogin: false,
            itemTypes: [
                { name: "Events", id: 1 },
                { name: "Locations", id: 4 },
                { name: "Resources", id: 6 },
                { name: "Organizations", id: 2 },
            ],
        },
        "event-form": {
            name: "Event Form",
            mode: "form",
            canCreateEvent: false,
            canLogin: true,
            itemTypes: [] as any[],
        },
        express: {
            name: "Express Form",
            mode: "form",
            canCreateEvent: false,
            canLogin: true,
            itemTypes: [] as any[],
        },
        "details-list": {
            name: "Event Details List",
            mode: "query",
            canCreateEvent: false,
            canLogin: false,
            itemTypes: [{ name: "Events", id: 1 }],
        },
    } as const,

    itemTypes: {
        1: "events",
        2: "organizations",
        4: "locations",
        6: "resources",
    },

    elements: [
        { display: "Event Name", name: "name", label: "Event Name", included: false },
        { display: "Event Title", name: "title", label: "Event Title", included: false },
        { display: "Event Title Or Name", name: "titleOrName", label: "Event Title Or Name", included: false },
        { display: "Event Start", name: "start", label: "Event Start", included: false },
        { display: "Event End", name: "end", label: "Event End", included: false },
        { display: "Setup", name: "setup", label: "Setup", included: false },
        { display: "Takedown", name: "takedown", label: "Takedown", included: false },
        { display: "State", name: "state", label: "State", included: false },
        { display: "Description", name: "descr", label: "Description", included: false },
        { display: "Expected", name: "expected", label: "Expected", included: false },
        { display: "Registered", name: "registered", label: "Registered", included: false },
        { display: "Sponsor", name: "sponsor", label: "Sponsor", included: false },
        { display: "Locations", name: "locations", label: "Locations", included: false },
    ],

    dateFormats: [
        { val: "short", txt: "M/d/yy, h:mm a", ex: "6/15/20, 9:03 AM" },
        { val: "medium", txt: "MMM d, y, h:mm:ss a", ex: "Jun 15, 2020, 9:03:01 AM" },
        { val: "long", txt: "MMMM d, y, h:mm:ss a z", ex: "June 15, 2020 at 9:03:01 AM GMT+1" },
        {
            val: "full",
            txt: "EEEE, MMMM d, y, h:mm:ss a zzzz",
            ex: "Monday, June 15, 2020 at 9:03:01 AM GMT+01:00",
        },
        { val: "shortDate", txt: "M/d/yy", ex: "6/15/2020" },
        { val: "mediumDate", txt: "MMM d, y", ex: "Jun 15, 2020" },
        { val: "longDate", txt: "MMMM d, y", ex: "June 15, 2020" },
        { val: "fullDate", txt: "EEEE, MMMM d, y", ex: "Monday, June 15, 2020" },
        { val: "shortTime", txt: "h:mm a", ex: "9:03 AM" },
        { val: "mediumTime", txt: "h:mm:ss a", ex: "9:03:01 AM" },
        { val: "longTime", txt: "h:mm:ss a z", ex: "9:03:01 AM GMT+1" },
        { val: "fullTime", txt: "h:mm:ss a zzzz", ex: "9:03:01 AM GMT+01:00" },
    ],

    getPreviewLink: function (config: EmbeddedConfig) {
        let url = window.ProData.baseUrl + "/embedded/";
        const embeddedTypeData = S25EmbeddingUtil.types[config.embeddedType];
        const mode = embeddedTypeData.mode === "form" ? "form" : config.queryId ? "query" : "item";
        const queryId = mode === "query" ? config.queryId : null;
        const itemTypeId = config.itemTypeId;
        const compSubject =
            itemTypeId === 4 ? "location" : itemTypeId === 6 ? "resource" : itemTypeId === 2 ? "organization" : "event";
        url += config.embeddedType.replace("event-form", "rose?allowEventCreation=T");
        url = S25Util.addParam(url, "comptype", config.comptype);
        url = S25Util.addParam(url, "compsubject", compSubject);
        url = S25Util.addParam(url, "itemTypeId", itemTypeId);
        url = S25Util.addParam(url, "itemId", config.itemId);
        url = S25Util.addParam(url, "queryId", queryId);
        url = S25Util.addParam(url, "embeddedConfigToken", config.configToken);
        return url;
    },
};
