import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { EmbeddedConfigService } from "../../../s25-embedding-config/embedded.config.service";
import { S25EmbeddingStylesFormConst } from "./s25.embedding.styles.form.const";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { ValueOf } from "../../../../pojo/Util";
import { TelemetryService } from "../../../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-embedding-styles")
@Component({
    selector: "s25-ng-embedding-styles",
    template: `
        <s25-simple-collapse [headerText]="'Custom CSS'" [titleText]="'Custom CSS'">
            <div class="styles">
                <s25-toggle-button
                    [modelValue]="isFormMode"
                    (modelValueChange)="onModeChange($event)"
                    [falseLabel]="'Code Mode'"
                    [trueLabel]="'Form Mode'"
                ></s25-toggle-button>
                <p class="ngFinePrint c-margin-bottom--single">
                    Important: All added styles will be cleared when changing from Code Mode to Form Mode, but if
                    switching from Form Mode to Code Mode styles will be kept and transferred. You can undo this change
                    by clicking the 'Cancel' button at the bottom of the page if you have not saved your changes.
                </p>

                @if (isFormMode) {
                    @if (allowEventCreation && type !== "event-form") {
                        <div class="tabs">
                            <button [class.active]="activeTab === 'main'" (click)="activeTab = 'main'" class="tab">
                                {{ typeDisplay }}
                            </button>
                            <button
                                [class.active]="activeTab === 'eventForm'"
                                (click)="activeTab = 'eventForm'"
                                class="tab"
                            >
                                Event Form
                            </button>
                        </div>
                    }
                    @if (allowEventCreation && type !== "event-form") {
                        <div class="tabsBottomBorder c-margin-bottom--single"></div>
                    }
                    @if (activeTab === "main") {
                        <div class="tabPanel">
                            <button
                                class="aw-button aw-button--outline c-margin-bottom--half"
                                (click)="addCustomFont()"
                            >
                                Add Custom Font
                            </button>
                            @for (element of elements; track element) {
                                <div class="c-margin-bottom--single">
                                    <p class="ngBold c-margin-top--half">{{ element.display }}</p>
                                    <p class="ngFinePrint c-margin-bottom--half">{{ element.help }}</p>
                                    <ng-container
                                        [ngTemplateOutlet]="styles"
                                        [ngTemplateOutletContext]="{
                                            elementName: element.name,
                                            styles: element.styles,
                                        }"
                                    ></ng-container>
                                    @if (element.secondaryClass && element.secondaryStyles) {
                                        <ng-container
                                            [ngTemplateOutlet]="styles"
                                            [ngTemplateOutletContext]="{
                                                elementName: element.name,
                                                styles: element.secondaryStyles,
                                            }"
                                        >
                                        </ng-container>
                                    }
                                    <ng-template #styles let-elementName="elementName" let-styles="styles">
                                        @for (style of styles; track style) {
                                            <div class="c-margin-bottom--half elementStyleOptions">
                                                <label [for]="elementName + style.name">{{ style.display }}: </label>
                                                @if (!style.dropdown) {
                                                    <input
                                                        [name]="elementName + style.name"
                                                        [id]="elementName + style.name"
                                                        [attr.prop]="style.prop"
                                                        class="c-input"
                                                        type="text"
                                                        [(ngModel)]="style.value"
                                                    />
                                                }
                                                @if (style.dropdown) {
                                                    <select
                                                        [name]="elementName + style.name"
                                                        [id]="elementName + style.name"
                                                        [attr.prop]="style.prop"
                                                        class="c-input"
                                                        [(ngModel)]="style.value"
                                                    >
                                                        @for (option of style.options; track option) {
                                                            <option [value]="option.value">
                                                                {{ option.display }}
                                                            </option>
                                                        }
                                                    </select>
                                                }
                                                <p
                                                    class="ngInlineBlock ngFinePrint c-margin-left--half c-margin-top--half"
                                                >
                                                    {{ style.help }}
                                                </p>
                                            </div>
                                        }
                                    </ng-template>
                                </div>
                            }
                        </div>
                    }
                    @if (activeTab === "eventForm" && allowEventCreation && type !== "event-form") {
                        <div class="tabPanel">
                            <p class="ngFinePrint c-margin-top--single">
                                Below are the styles for the Event Form that will show if a user clicks to create an
                                event from your
                                {{ typeDisplay }} embedding.
                            </p>
                            @for (element of secondaryElements; track element) {
                                <div>
                                    <p class="ngBold c-margin-top--half">{{ element.display }}</p>
                                    <p class="ngFinePrint c-margin-bottom--half">{{ element.help }}</p>
                                    @for (style of element.styles; track style) {
                                        <div class="c-margin-bottom--half elementStyleOptions">
                                            <label [for]="element.name + style.name">{{ style.display }}: </label>
                                            <input
                                                [name]="element.name + style.name"
                                                [id]="element.name + style.name"
                                                [attr.prop]="style.prop"
                                                class="c-input"
                                                type="text"
                                                [(ngModel)]="style.value"
                                            />
                                            <p class="ngInlineBlock ngFinePrint c-margin-left--half c-margin-top--half">
                                                {{ style.help }}
                                            </p>
                                        </div>
                                    }
                                    @if (element.secondaryClass && element.secondaryStyles) {
                                        @for (style of element.secondaryStyles; track style) {
                                            <div class="c-margin-bottom--half elementStyleOptions">
                                                <label [for]="element.name + style.name">{{ style.display }}: </label>
                                                <input
                                                    [name]="element.name + style.name"
                                                    [id]="element.name + style.name"
                                                    [attr.prop]="style.prop"
                                                    class="c-input"
                                                    type="text"
                                                    [(ngModel)]="style.value"
                                                />
                                                <p
                                                    class="ngInlineBlock ngFinePrint c-margin-left--half c-margin-top--half"
                                                >
                                                    {{ style.help }}
                                                </p>
                                            </div>
                                        }
                                    }
                                </div>
                            }
                        </div>
                    }
                }

                @if (!isFormMode) {
                    <textarea [(ngModel)]="css" class="c-input"></textarea>
                }
            </div>
        </s25-simple-collapse>
    `,
    styles: `
        .styles {
            padding: 1em;
        }

        .tabs {
            margin-left: 1.5em;
            overflow: hidden;
            height: 2.25em;
            display: inline-flex;
        }

        .tab {
            border: 0;
            background-color: #eee;
            color: #000;
            padding: 0.3em 0.5em 0.5em 0.5em;
            margin-right: 3px;
            border-top: 3px solid transparent;
            border-bottom: 1px solid #ddd;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            cursor: pointer;
        }

        .tab.active {
            background-color: #fff;
            color: var(--color-primary);
            border: 1px solid #ddd;
            border-top: 3px solid var(--color-primary);
            border-bottom: none;
        }

        .tabsBottomBorder {
            margin-top: -1px;
            border-top: 1px solid #ddd;
            margin-left: -1em;
            margin-right: -1em;
        }

        .tabPanel {
            padding: 0 1em;
        }

        .elementStyleOptions {
            padding-left: 1em;
        }

        .elementStyleOptions label {
            width: 100px;
        }

        .elementStyleOptions input[prop="url"] {
            width: 80%;
        }

        .elementStyleOptions .ngFinePrint {
            display: none;
        }

        .elementStyleOptions input:focus ~ .ngFinePrint {
            display: inline-block;
        }

        textarea {
            display: block;
            width: 100% !important;
            height: 10em;
        }

        input,
        select {
            width: 15em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EmbeddingStylesComponent implements OnInit, OnChanges {
    @Input() type: ReturnType<typeof EmbeddedConfigService.parseConfig>["embeddedType"];
    @Input() css: ReturnType<typeof EmbeddedConfigService.parseConfig>["css"];
    @Input() allowEventCreation: ReturnType<typeof EmbeddedConfigService.parseConfig>["allowEventCreation"];
    @Input() isFormMode: boolean = false;

    @Output() cssChange = new EventEmitter<string>();
    @Output() isFormModeChange = new EventEmitter<boolean>();

    isInit = false;
    elements: Element[];
    secondaryElements: Element[];
    typeDisplay: string;
    Util = S25EmbeddingStylesFormConst;
    activeTab: "main" | "eventForm" = "main";

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type) this.ngOnInit();
    }

    ngOnInit() {
        this.setElements();

        if (this.css) {
            this.separateStyles(this.elements);
            if (this.css.includes("@import")) this.separateFonts();
            if (this.secondaryElements) this.separateStyles(this.secondaryElements);
        }

        this.isInit = true;
    }

    setElements() {
        this.secondaryElements = null;
        let elements: Element[];
        let secondaryElements: Element[];

        switch (this.type) {
            case "details-list":
                this.typeDisplay = "Event Details List";
                elements = this.Util.detailsListElements;
                break;
            case "calendar":
                this.typeDisplay = "Calendar";
                elements = this.Util.calendarElements;
                if (this.allowEventCreation) secondaryElements = this.Util.eventCreationArray;
                break;
            case "event-form":
                this.typeDisplay = "Event Form";
                elements = this.Util.eventFormElements;
                break;
            case "express":
                this.typeDisplay = "Express";
                elements = this.Util.expressElements;
                secondaryElements = this.Util.eventCreationArray;
                break;
            case "availability":
                this.typeDisplay = "Availability";
                elements = this.Util.availabilityDailyElements;
                secondaryElements = this.Util.eventCreationArray;
                break;
            case "availability-weekly":
            case "availability-weekly-mult-loc":
                this.typeDisplay = "Availability Weekly";
                elements = this.Util.availabilityWeeklyElements;
                secondaryElements = this.Util.eventCreationArray;
                break;
        }

        this.elements = S25Util.deepCopy(elements);
        if (secondaryElements) this.secondaryElements = S25Util.deepCopy(secondaryElements);
    }

    addCustomFont() {
        TelemetryService.sendWithSub("SysSettings", "Embedding", "AddCustFont");
        this.elements.unshift({
            name: "customFont",
            display: "Custom Font",
            class: "@import",
            help: "To use a custom font from a web-based service, first copy and paste the url (href value) from the font link tag the service provides in the Source URL field below. You will then need to enter the font-family name provided into the Font Family field of the element(s) to which you would like to apply the font. If you would like to add multiple custom fonts you can do so with one combined url, or click the button above to add another separate url.",
            styles: [S25Util.clone(S25EmbeddingStylesFormConst.properties.url)],
        });
    }

    onModeChange(mode: boolean) {
        if (mode) {
            // Changed TO form mode
            this.clearStyles(this.elements);
            if (this.secondaryElements) this.clearStyles(this.secondaryElements);
        } else {
            // Changed TO code mode
            this.css = this.getCss();
            this.cssChange.emit(this.css);
        }

        this.isFormMode = mode;
        this.isFormModeChange.emit(this.isFormMode);
    }

    clearStyles(elements: Element[]) {
        for (let element of elements) {
            for (let style of element.styles) style.value = null;
            if (element.secondaryStyles) {
                for (let style of element.secondaryStyles) style.value = null;
            }
        }
    }

    separateStyles(elements: Element[]) {
        for (let element of elements) {
            const classIndex = this.css.indexOf(element.class);
            if (classIndex !== -1) this.reformatStyles(classIndex, element, element.styles);
            if (element.secondaryClass && element.secondaryStyles) {
                const classIndex = this.css.indexOf(element.secondaryClass);
                if (classIndex !== -1) this.reformatStyles(classIndex, element, element.secondaryStyles);
            }
        }
    }

    reformatStyles(start: number, element: Element, styles: Style[]) {
        // loops through styles array separating css string into individual strings for the form; called above in separateStyles
        const end = this.css.indexOf("}", start);
        const classStyles = this.css.substring(start, end);
        for (let style of styles) {
            let propIndex = classStyles.indexOf(style.prop);
            if (propIndex === -1) continue;
            if (style.prop === "color") {
                // check to differentiate between color and background-color
                const backgroundCheck = classStyles.charAt(propIndex - 1).toString();
                if (backgroundCheck === "-") propIndex = classStyles.indexOf(style.prop, propIndex + 1);
            }

            const propStart = classStyles.indexOf(":", propIndex);
            const propEnd = classStyles.indexOf(";", propIndex);
            let propStyle = classStyles.substring(propStart + 1, propEnd).trim();
            // Remove any !important for user display
            if (element.important) propStyle = propStyle.substring(0, propStyle.length - 10).trim();
            style.value = propStyle;
        }
    }

    separateFonts() {
        let match: RegExpExecArray;
        const importRegEx = /@import/g;
        while ((match = importRegEx.exec(this.css)) !== null) {
            const start = this.css.indexOf("(", match.index);
            const end = this.css.indexOf(")", start);
            const url = this.css.substring(start + 2, end - 1);
            if (!url.length) continue;

            this.elements.unshift({
                name: "customFont",
                display: "Custom Font",
                class: "@import",
                help: "To use a custom font from a web-based service, first copy and paste the url (href value) from the font link tag the service provides in the Source URL field below. You will then need to enter the font-family name provided into the Font Family field of the element(s) to which you would like to apply the font. If you would like to add multiple custom fonts you can do so with one combined url, or click the button above to add another separate url.",
                styles: [
                    {
                        name: "url" + match.index,
                        prop: "url",
                        display: "Source URL",
                        value: url,
                        help: "Copy and paste the link to the font (the href value) without quotes. Example: https://fonts.googleapis.com/css2?family=Hahmlet:wght@400;500&display=swap",
                    },
                ],
            });
        }
    }

    getCss() {
        if (!this.isFormMode) return this.css;
        let css = "";
        for (let element of this.elements.concat(this.secondaryElements || [])) {
            let styleString = element.class;
            if (element.class !== "@import") {
                styleString += " { ";
                for (let style of element.styles) {
                    if (!style.value || !style.prop) continue;
                    styleString += `${style.prop}: ${style.value}${element.important ? " !important" : ""}; `;
                }
            }

            if (element.hiddenProps) {
                for (let object of element.hiddenProps) {
                    for (let style of element.styles) {
                        if (object.sameAs.name === style.name && style.value)
                            styleString += `${object.prop.prop}: ${style.value} !important;`;
                    }
                }
            }

            if (element.class === "@import") {
                if (element.styles[0].value) styleString += ` url('${element.styles[0].value}'); `;
                else styleString = "";
            } else styleString += "} \n\n";

            if (element.secondaryClass) {
                // check to see if element has nested styles
                let styleString2 = `${element.secondaryClass} {`;
                for (let style of element.secondaryStyles) {
                    if (!style.value || !style.prop) continue;
                    styleString2 += `${style.prop}: ${style.value}${element.important ? " !important" : ""}; `;
                }

                if (element.secondaryHiddenProps) {
                    for (let object of element.secondaryHiddenProps) {
                        for (let style of element.styles) {
                            if (object.sameAs.name === style.name && style.value)
                                styleString2 += `${object.prop.prop}: ${style.value} !important;`;
                        }
                    }
                }

                styleString2 += "} \n\n";
                styleString += styleString2;
            }

            css += styleString;
        }

        return css;
    }
}

type Style = ValueOf<typeof S25EmbeddingStylesFormConst.properties> & { value?: string };

type Element = {
    name: string;
    display: string;
    help?: string;
    important?: boolean;
    class: string;
    styles: Style[];
    secondaryClass?: string;
    secondaryStyles?: Style[];
    hiddenProps?: {
        prop: Style;
        sameAs: Style;
    }[];
    secondaryHiddenProps?: {
        prop: Style;
        sameAs: Style;
    }[];
};
