import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { EventCreationFormService, EventFormConfigData } from "../../../../services/event.creation.form.service";
import { EmbeddedConfig, EmbeddedConfigService } from "../../../s25-embedding-config/embedded.config.service";
import { S25EmbeddingUtil } from "./s25.embedding.util";
import { ValueOf } from "../../../../pojo/Util";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { Proto } from "../../../../pojo/Proto";
import { LoginService } from "../../../../services/login.service";
import { Debounce } from "../../../../decorators/debounce.decorator";
import { ContactService } from "../../../../services/contact.service";
import { DropDownItem } from "../../../../pojo/DropDownItem";
import { S25EmbeddingStylesComponent } from "./s25.embedding.styles.component";
import { S25Const } from "../../../../util/s25-const";
import URLString = Proto.URLString;
import { TelemetryService } from "../../../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-embedded-config")
@Component({
    selector: "s25-ng-embedded-config",
    template: `
        @if (!isInit) {
            <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
        }

        @if (isInit) {
            <div>
                <p>
                    <strong>Third step:</strong> Complete the following fields to generate the script you will embed in
                    the body of your page.
                </p>
                <div class="c-margin-top--single">
                    <div class="embedRow">
                        <label for="name" class="ngBold">Name </label>
                        <s25-ng-info-message>
                            This name is only used internally to identify your saved config.
                        </s25-ng-info-message>
                        <input
                            id="name"
                            class="c-input ngBlock"
                            type="text"
                            maxlength="40"
                            [(ngModel)]="config.configName"
                        />
                    </div>
                    <div class="embedRow">
                        <label for="target" class="ngBold">Target ID </label>
                        <s25-ng-info-message>
                            Enter the HTML element ID you want the embedded content to appear within.
                        </s25-ng-info-message>
                        <input
                            id="target"
                            class="c-input ngBlock"
                            type="text"
                            maxlength="256"
                            [(ngModel)]="config.targetId"
                        />
                    </div>
                    <div class="embedRow">
                        <label for="type" class="ngBold">Content Type </label>
                        <s25-ng-help-button [topic]="'embedding'"></s25-ng-help-button>
                        <select
                            id="type"
                            class="cn-form__control ngBlock"
                            [(ngModel)]="config.embeddedType"
                            (ngModelChange)="onTypeChange()"
                        >
                            @for (type of Util.typeList; track type) {
                                <option [value]="type">{{ Util.types[type].name }}</option>
                            }
                        </select>
                    </div>
                    @if (embeddedTypeData?.itemTypes?.length) {
                        <div class="embedRow">
                            <label for="itemType" class="ngBold">Item Type </label>
                            <select
                                id="itemType"
                                class="cn-form__control ngBlock"
                                [(ngModel)]="config.itemTypeId"
                                (ngModelChange)="onItemTypeChange()"
                            >
                                @for (itemType of embeddedTypeData.itemTypes; track itemType) {
                                    <option [value]="itemType.id">
                                        {{ itemType.name }}
                                    </option>
                                }
                            </select>
                        </div>
                    }
                    @if (embeddedTypeData.mode === "either") {
                        <div class="embedRow">
                            <label for="chooseToDisplay"><span class="ngBold">Choose To Display</span></label>
                            <fieldset class="radios">
                                <s25-ng-radio [(modelValue)]="mode" [name]="'chooseToDisplay'" [value]="'item'">
                                    Single Item
                                </s25-ng-radio>
                                <s25-ng-radio [(modelValue)]="mode" [name]="'chooseToDisplay'" [value]="'query'">
                                    Search Results
                                </s25-ng-radio>
                            </fieldset>
                        </div>
                    }
                    @if (mode === "item") {
                        <div class="embedRow">
                            <label for="item-selection" class="ngBold">Item</label>
                            <s25-ng-info-message> Select the item you wish to display. </s25-ng-info-message>
                            <s25-ng-dropdown-search-criteria
                                id="item-selection"
                                [type]="Util.itemTypes[config.itemTypeId]"
                                [(chosen)]="chosenItem"
                                (chosenChange)="config.itemId = $event.itemId"
                            ></s25-ng-dropdown-search-criteria>
                        </div>
                    }
                    @if (mode === "query") {
                        <div class="embedRow">
                            <label for="queryId" class="ngBold">Search</label>
                            <s25-ng-info-message>
                                Select a search which returns the items you wish to display.
                            </s25-ng-info-message>
                            <s25-ng-search-dropdown
                                *s25-ng-trigger-rerender="config.itemTypeId"
                                [itemTypeId]="config.itemTypeId"
                                [allowNonQueryId]="false"
                                [(chosen)]="searchSelector"
                                (chosenChange)="onSearchSelection($event)"
                            ></s25-ng-search-dropdown>
                        </div>
                        <div class="embedRow">
                            <label class="ngBold">Search Selector</label>
                            <s25-ng-checkbox class="ngBlock" [(modelValue)]="config.hasMultiQuery">
                                Enable Search Selector
                            </s25-ng-checkbox>
                            @if (config.hasMultiQuery) {
                                <s25-toggle-button
                                    [(modelValue)]="config.multiQueryDropdown"
                                    [falseLabel]="'Multi-Search Selector'"
                                    [trueLabel]="'Single Search Selector'"
                                    class="multiQueryToggle"
                                ></s25-toggle-button>
                            }
                            @if (config.hasMultiQuery) {
                                <s25-multi-query
                                    [selectedQueries]="selectedQueries"
                                    (onItemChange)="onMultiQueryChange($event)"
                                    [itemTypeId]="config.itemTypeId"
                                    [noUpdate]="true"
                                    class="multiSearchSelect"
                                ></s25-multi-query>
                            }
                        </div>
                    }
                    @if (embeddedTypeData.canCreateEvent || embeddedTypeData.canLogin || config.allowEventCreation) {
                        <div class="embedRow">
                            <label class="ngBold">Allow Event Creation</label>
                            @if (embeddedTypeData.canCreateEvent) {
                                <s25-ng-checkbox class="ngBlock" [(modelValue)]="config.allowEventCreation">
                                    Allow Event Creation
                                </s25-ng-checkbox>
                            }
                            @if (embeddedTypeData.canLogin || config.allowEventCreation) {
                                <s25-ng-checkbox
                                    class="ngBlock"
                                    [(modelValue)]="requireLogin"
                                    (modelValueChange)="onRequireLoginChange($event)"
                                >
                                    Require Login For Event Creation
                                </s25-ng-checkbox>
                            }
                        </div>
                    }
                    @if (config.allowEventCreation || config.embeddedType === "event-form") {
                        <div class="embedRow">
                            <label for="eventFormConfig" class="ngBold">Event Form Config</label>
                            <select
                                [(ngModel)]="config.eventFormId"
                                id="eventFormConfig"
                                class="cn-form__control ngBlock"
                            >
                                @for (eventConfig of eventConfigs; track eventConfig) {
                                    <option [value]="eventConfig.itemId">
                                        {{ eventConfig.itemName }}
                                    </option>
                                }
                            </select>
                            <button
                                class="aw-button aw-button--outline  c-margin-top--quarter"
                                (click)="config.eventFormId = null"
                            >
                                Remove
                            </button>
                        </div>
                    }
                    @if (!requireLogin) {
                        <div class="embedRow">
                            <label class="ngBold">Session Contact</label>
                            <s25-ng-info-message>
                                Required for forms. The selected contact will be the identified user. If none is
                                selected, your 25Live Viewer Seat will be used.
                            </s25-ng-info-message>
                            <s25-contact-dropdown
                                [r25UserOnly]="true"
                                [(chosen)]="sessionContact"
                            ></s25-contact-dropdown>
                            @if (loading.session) {
                                <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
                            }
                            <div>
                                @if (sessionContact) {
                                    <button
                                        class="aw-button aw-button--outline c-margin-top--quarter c-margin-right--quarter"
                                        (click)="createSession()"
                                        [disabled]="loading.session"
                                    >
                                        Create Session ID
                                    </button>
                                }
                                @if (sessionContact) {
                                    <button
                                        class="aw-button aw-button--outline"
                                        (click)="deleteSession()"
                                        [disabled]="loading.session"
                                    >
                                        Delete
                                    </button>
                                }
                            </div>
                        </div>
                        @if (config.sessionId) {
                            <div class="embedRow sessionId">
                                <label class="ngBold">The session ID is shown for informational purposes only:</label>
                                <p class="id">{{ config.sessionId }}</p>
                            </div>
                        }
                    }
                    @if (config.embeddedType === "details-list") {
                        <div class="embedRow">
                            <label class="ngBold">Subscribe</label>
                            <s25-ng-checkbox class="ngBlock" [(modelValue)]="config.allowSubscribe"
                                >Show Subscribe Option</s25-ng-checkbox
                            >
                        </div>
                        <div class="embedRow">
                            <label for="grouping" class="ngBold">Grouping</label>
                            <select
                                [(ngModel)]="config.detailsList.grouping"
                                id="grouping"
                                class="cn-form__control ngBlock"
                            >
                                <option [value]="'day'">Day</option>
                                <option [value]="'week'">Week</option>
                                <option [value]="'month'">Month</option>
                            </select>
                        </div>
                        <div class="embedRow">
                            <label for="groupDateFormat" class="ngBold">Group Date Format</label>
                            <s25-ng-info-message>
                                This is the date format that will display for the selected grouping. It acts as a
                                heading for events that fall within a grouping.
                            </s25-ng-info-message>
                            <select
                                [(ngModel)]="config.detailsList.groupNameFormat"
                                id="groupDateFormat"
                                class="cn-form__control ngBlock"
                                aria-label="Select Group Date Format"
                            >
                                @for (format of Util.dateFormats; track format) {
                                    <option [value]="format.val">
                                        {{ format.txt }}
                                    </option>
                                }
                            </select>
                        </div>
                        <div class="embedRow">
                            <label for="elementDateFormat" class="ngBold">Element Date Format</label>
                            <s25-ng-info-message>
                                This is the default date format for the start, end, setup, and takedown times for the
                                event.
                            </s25-ng-info-message>
                            <select
                                [(ngModel)]="config.detailsList.elementDateFormat"
                                id="elementDateFormat"
                                class="cn-form__control ngBlock"
                                aria-label="Select Element Date Format"
                            >
                                @for (format of Util.dateFormats; track format) {
                                    <option [value]="format.val">
                                        {{ format.txt }}
                                    </option>
                                }
                            </select>
                        </div>
                        <s25-simple-collapse [headerText]="'Elements'" [titleText]="'Event Details List Elements'">
                            <div class="elements">
                                <s25-ng-info-message [isInline]="true">
                                    The order of the visible elements displayed can be changed by clicking and dragging
                                    the elements into the desired order. Keyboard users can tab to the row, then "pick
                                    it up" using enter and move it with the arrow keys.
                                </s25-ng-info-message>
                                <ul s25-ng-dnd-sortable [items]="elements">
                                    @for (element of elements; track element; let i = $index) {
                                        <li s25-ng-dnd-sortable-item [index]="i">
                                            <s25-ng-drag-handle></s25-ng-drag-handle>
                                            <div>
                                                <label class="ngBold">{{ element.display }}</label>
                                                <div>
                                                    <label>
                                                        <span>Include:</span>
                                                        <s25-ng-checkbox
                                                            [(modelValue)]="element.included"
                                                        ></s25-ng-checkbox>
                                                    </label>
                                                    @if (element.included && element.name === "sponsor") {
                                                        <label>
                                                            <span>Prefer Sponsor Title:</span>
                                                            <s25-ng-checkbox
                                                                [modelValue]="!!config.detailsList.preferOrgTitle"
                                                                (modelValueChange)="
                                                                    config.detailsList.preferOrgTitle = $event
                                                                "
                                                            ></s25-ng-checkbox>
                                                        </label>
                                                    }
                                                    @if (element.included && element.name === "locations") {
                                                        <label>
                                                            <span>Show Location Formal Name:</span>
                                                            <s25-ng-checkbox
                                                                [modelValue]="
                                                                    !!config.detailsList.showLocationFormalName
                                                                "
                                                                (modelValueChange)="
                                                                    config.detailsList.showLocationFormalName = $event
                                                                "
                                                            ></s25-ng-checkbox>
                                                        </label>
                                                    }
                                                    @if (element.included && element.name === "start") {
                                                        <label>
                                                            <span>Start Date Format:</span>
                                                            <p class="ngFinePrint c-margin-top--quarter">
                                                                Note: This will default to the Element Date Format if no
                                                                Start Date Format is selected.
                                                            </p>
                                                            <select
                                                                [(ngModel)]="config.detailsList.startDateFormat"
                                                                class="cn-form__control ngBlock"
                                                                aria-label="Select Start Date Format"
                                                            >
                                                                @for (format of Util.dateFormats; track format) {
                                                                    <option [value]="format.val">
                                                                        {{ format.txt }}
                                                                    </option>
                                                                }
                                                            </select>
                                                        </label>
                                                    }
                                                    @if (element.included && element.name === "end") {
                                                        <label>
                                                            <span>End Date Format:</span>
                                                            <p class="ngFinePrint c-margin-top--quarter">
                                                                Note: This will default to the Element Date Format if no
                                                                Start Date Format is selected.
                                                            </p>
                                                            <select
                                                                [(ngModel)]="config.detailsList.endDateFormat"
                                                                class="cn-form__control ngBlock"
                                                                aria-label="Select End Date Format"
                                                            >
                                                                @for (format of Util.dateFormats; track format) {
                                                                    <option [value]="format.val">
                                                                        {{ format.txt }}
                                                                    </option>
                                                                }
                                                            </select>
                                                        </label>
                                                        <label>
                                                            <span>Show Start and End Times on Same Line:</span>
                                                            <s25-ng-checkbox
                                                                [modelValue]="!!config.detailsList.timeInline"
                                                                (modelValueChange)="element.label = ''"
                                                            ></s25-ng-checkbox>
                                                        </label>
                                                    }
                                                    @if (
                                                        !(
                                                            element.included &&
                                                            element.name === "end" &&
                                                            config.detailsList.timeInline
                                                        )
                                                    ) {
                                                        <label>
                                                            <span>Label:</span>
                                                            <input
                                                                class="c-input"
                                                                type="text"
                                                                [(ngModel)]="element.label"
                                                            />
                                                        </label>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </s25-simple-collapse>
                    }
                    @if (config.embeddedType) {
                        <s25-ng-embedding-styles
                            [type]="config.embeddedType"
                            [css]="config.css"
                            [allowEventCreation]="config.allowEventCreation"
                            [(isFormMode)]="config.formMode"
                        ></s25-ng-embedding-styles>
                    }
                    @if (config.configToken && previewLink) {
                        <div class="c-margin-bottom--single c-margin-top--single">
                            <p class="c-margin-bottom--quarter"><strong>Preview Embedded Content</strong></p>
                            <a class="aw-button aw-button--primary" target="_blank" [href]="previewLink">Preview</a>
                        </div>
                    }
                    @if (config.configToken) {
                        <div>
                            <p><strong>Your Embedded Content Script</strong></p>
                            <p><strong>Last step:</strong> Add this script to the body of your page.</p>
                            <pre>
                      &lt;div id="{{ config.targetId }}"&gt;&lt;/div&gt;
                      &lt;script type="text/javascript"&gt;
                      embedded25.fromConfig.create('{{ config.instId }}', '{{ config.configToken }}');
                      &lt;/script&gt;
                      </pre
                            >
                        </div>
                    }
                    @if (loading.save) {
                        <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
                    }
                    <div class="buttons">
                        <button class="aw-button aw-button--primary" (click)="onSave()" [disabled]="loading.save">
                            Save
                        </button>
                        <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
                    </div>
                </div>
            </div>
        }
    `,
    styles: `
        .embedRow {
            margin-bottom: 1em;
        }

        label {
            margin-bottom: 0.5em;
        }

        s25-ng-help-button {
            color: #2573a7;
        }

        s25-ng-help-button,
        s25-ng-info-message {
            margin-left: 0.5em;
        }

        .elements > s25-ng-info-message {
            margin: 0;
        }

        ::ng-deep s25-ng-help-button svg {
            padding-bottom: 2px;
        }

        .radios {
            display: flex;
            gap: 0.5em;
            align-items: center;
        }

        select,
        .c-input,
        s25-contact-dropdown,
        s25-ng-search-dropdown,
        s25-ng-dropdown-search-criteria {
            width: 25em;
            display: block;
        }

        .multiQueryToggle {
            margin-bottom: 1em;
            display: block;
        }

        .elements {
            padding: 1em;
        }

        .elements ul {
            padding-inline-start: 0;
        }

        .elements ul li {
            list-style-type: none;
            padding: 0.5em 0;
            border-bottom: 1px solid #ddd;
            display: flex;
        }

        .elements ul li > div > div {
            padding-left: 1.5em;
        }

        .elements ul li > div label {
            display: block;
        }

        .elements ul li > div label span {
            min-width: 5em;
            display: inline-block;
            padding-right: 0.5em;
        }

        .elements ul li > div input {
            display: inline-block;
            width: 20em;
        }

        s25-ng-drag-handle {
            margin: auto 0;
        }

        pre {
            font-size: 90%;
            line-height: 1.2em;
            font-family: "Courier 10 Pitch", Courier, monospace;
            white-space: pre;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -o-pre-wrap;
            width: fit-content;
            height: 1%;
            display: block;
            clear: both;
            color: #555;
            padding: 2em 1em;
            margin: 1em 40px;
            background: #f4f4f4;
            border: solid 1px #e1e1e1;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
            padding: 1em 0;
        }

        .sessionId {
            font-size: 0.9em;
        }

        .sessionId .id {
            color: #757575;
        }

        ::ng-deep .nm-party--on s25-ng-embedded-config .sessionId .id {
            color: #aba9a9;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EmbeddingConfigComponent implements OnInit {
    @Input() token?: string;
    @Input() editMode: "create" | "copy" | "edit" = "edit";

    @Output() created = new EventEmitter<string>();
    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    @ViewChild(S25EmbeddingStylesComponent) stylesComponent: S25EmbeddingStylesComponent;

    isInit = false;
    Util = S25EmbeddingUtil;
    config: ReturnType<typeof EmbeddedConfigService.parseConfig>;
    eventConfigs: EventFormConfigData[];
    chosenEventConfig: EventFormConfigData;
    embeddedTypeData: ValueOf<typeof S25EmbeddingUtil.types>;
    mode: "item" | "query" | "form";
    requireLogin: boolean;
    elements: typeof S25EmbeddingUtil.elements;
    previewLink: URLString;
    selectedQueries: any[] = [];
    sessionContact: DropDownItem;
    loading: Loaders = {};
    searchSelector: DropDownItem;
    chosenItem: DropDownItem;

    constructor(private changeDetector: ChangeDetectorRef) {}

    getConfig(): Promise<EmbeddedConfig> {
        if (this.editMode !== "create") return EmbeddedConfigService.getConfig(this.token);

        return Promise.resolve({
            instId: S25Const.instanceId,
            configName: "New Config",
            embeddedType: "availability",
            itemTypeId: "4",
        } as any);
    }

    async ngOnInit() {
        const [config, eventFormConfigs] = await Promise.all([
            this.getConfig(),
            EventCreationFormService.getAllConfigs(),
        ]);

        this.config = EmbeddedConfigService.parseConfig(config);
        this.eventConfigs = eventFormConfigs;
        const { eventFormId, embeddedType, queryId, css, formMode, sessionId, allowEventCreation, queryName } =
            this.config;

        if (this.editMode === "copy") {
            this.config.configToken = null;
            this.config.configName += " copy";
        }
        this.chosenEventConfig = this.eventConfigs.find((config) => config.itemId == eventFormId);
        this.embeddedTypeData = S25EmbeddingUtil.types[embeddedType];
        this.mode = this.embeddedTypeData.mode === "form" ? "form" : queryId ? "query" : "item";
        this.config.formMode = formMode ?? !css;
        this.requireLogin =
            !sessionId &&
            (allowEventCreation || ["event-form", "express"].includes(embeddedType)) &&
            embeddedType !== "details-list";
        this.elements = S25Util.deepCopy(S25EmbeddingUtil.elements);
        this.selectedQueries = this.config.queryList || [];
        if (queryId) this.searchSelector = { itemId: queryId, itemName: queryName };
        if (this.config.sessionId) {
            const contact = await LoginService.getLoginFromSessionId(this.config.sessionId);
            this.sessionContact = { itemName: contact.contactName, itemId: contact.userId, itemTypeId: 3 };
        }
        this.chosenItem = { itemId: this.config.itemId, itemName: this.config.itemName };

        this.setDetailsListElements();

        this.previewLink = S25EmbeddingUtil.getPreviewLink(this.config as any);
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    setDetailsListElements() {
        if (this.config.embeddedType === "details-list") {
            this.config.detailsList.element = this.config.detailsList.element || [];
            const included = new Map<string, any>();
            for (let item of this.config.detailsList.element) included.set(item.name, item);
            for (let item of this.elements) {
                if (!included.has(item.name)) continue;
                item.label = included.get(item.name).display;
                item.included = true;
            }
            this.elements.sort((a, b) => {
                return included.get(a.name)?.sortOrder - included.get(b.name)?.sortOrder;
            });
        }
    }

    onTypeChange() {
        this.embeddedTypeData = S25EmbeddingUtil.types[this.config.embeddedType];
        this.config.itemTypeId = this.embeddedTypeData.itemTypes[0]?.id;

        let mode = this.embeddedTypeData.mode;
        if (mode === "either") mode = this.config.queryId ? "query" : "item";
        this.mode = mode;

        this.config.css = "";

        if (this.config.embeddedType === "details-list") {
            this.config.detailsList = this.config.detailsList || {};
            this.setDetailsListElements();
        }

        this.resetSearchSelection();
        this.resetMultiQuery();
        this.changeDetector.detectChanges();
    }

    onItemTypeChange() {
        this.resetItemSelection();
        this.resetSearchSelection();
        this.resetMultiQuery();
        this.changeDetector.detectChanges();
    }

    resetItemSelection() {
        this.chosenItem = {};
    }

    resetSearchSelection() {
        this.config.queryId = null;
        this.config.queryName = "";
        this.searchSelector = null;
    }

    resetMultiQuery() {
        this.config.hasMultiQuery = false;
        this.selectedQueries = [];
    }

    onMultiQueryChange(change: { item: any; selected: any[] }) {
        this.selectedQueries = change.selected;
    }

    @Debounce(300)
    async createSession() {
        this.startLoading("session");
        this.config.sessionId = await ContactService.createPersistentSession(this.sessionContact.itemId as number);
        this.stopLoading("session");
    }

    @Debounce(300)
    async deleteSession() {
        if (!this.sessionContact?.itemId) return;
        this.startLoading("session");
        await ContactService.deletePersistentSession(this.sessionContact.itemId as number);
        this.config.sessionId = "";
        this.stopLoading("session");
    }

    async onSave() {
        if (!this.validate()) return;

        let modeName = S25Util.firstCharToUpper(this.editMode);

        let typeName = S25Util.firstCharToUpper(S25Util.snakeToCamel(this.config.embeddedType.replace(/-/g, "_")));
        TelemetryService.sendWithSub("SysSettings", "Embedding", typeName + modeName);

        if (this.config.itemTypeId) {
            let itemTypeName = S25Util.firstCharToUpper(
                S25Util.snakeToCamel(S25Const.itemId2Name[this.config.itemTypeId]),
            );
            TelemetryService.sendWithSub("SysSettings", "Embedding", itemTypeName + modeName);
        }

        !this.config.formMode && TelemetryService.sendWithSub("SysSettings", "Embedding", "CodeMode");
        this.requireLogin && TelemetryService.sendWithSub("SysSettings", "Embedding", "RequireLogin");

        this.startLoading("save");

        if (this.config.embeddedType === "calendar") this.config.comptype = "calendar";
        if (this.config.embeddedType === "availability")
            this.config.comptype = this.mode === "item" ? "availability_daily" : "availability";

        this.config.css = this.stylesComponent.getCss();

        const { configName, embeddedType, comptype, targetId, sessionId, instId, itemId, queryId } = this.config;
        const { itemTypeId, allowEventCreation, allowSubscribe, css, hasMultiQuery, multiQueryDropdown } = this.config;
        const { formMode, eventFormId } = this.config;

        const data = {
            config_name: configName,
            embedded_type: embeddedType,
            comptype: comptype,
            target_id: targetId,
            session_id: !this.requireLogin ? sessionId : "",
            instance_id: instId,
            item_id: this.mode === "item" ? this.chosenItem.itemId : null,
            query_id: this.mode === "query" ? queryId : null,
            item_type_id: itemTypeId === undefined ? null : String(itemTypeId),
            allow_event_creation: allowEventCreation || ["event-form", "express"].includes(embeddedType) ? "T" : "F",
            allow_subscribe: allowSubscribe ? "T" : "F",
            css: css,
            has_multi_query: hasMultiQuery ? 1 : 0,
            multi_query_dropdown: multiQueryDropdown ? 1 : 0,
            query_list: this.mode === "query" ? this.selectedQueries.map((query) => query.itemId).join("+") : "",
            form_mode: formMode,
            event_form_id: parseInt(eventFormId as any as string),
            detailsList: null as any,
        };

        if (embeddedType === "details-list") {
            const { grouping, showLocationFormalName, preferOrgTitle, groupNameFormat, elementDateFormat } =
                this.config.detailsList;
            const { startDateFormat, endDateFormat, timeInline } = this.config.detailsList;

            data.detailsList = {
                grouping: grouping,
                show_location_formal_name: showLocationFormalName,
                prefer_org_title: preferOrgTitle,
                group_name_format: groupNameFormat,
                element_date_format: elementDateFormat,
                start_date_format: startDateFormat,
                end_date_format: endDateFormat,
                time_inline: timeInline,
                element: this.elements
                    .filter((element: any) => element.included)
                    .map((element: any, i: number) => ({
                        name: element.name,
                        display: element.label,
                        sort_order: i,
                    })),
            };
        }

        const [ok, error] = await S25Util.Maybe(
            EmbeddedConfigService.putConfig(this.config.configToken || "", { config: data }),
        );
        if (error) S25Util.showError(error, "There was a problem while saving. Please contact your administrator.");
        else if (this.editMode === "create" || this.editMode === "copy") {
            this.editMode = "edit";
            this.config.configToken = ok.root.configToken;
            this.previewLink = S25EmbeddingUtil.getPreviewLink(this.config as any);
            this.created.emit(this.config.configName);
        } else this.saved.emit();

        this.stopLoading("save");
    }

    onCancel() {
        this.cancelled.emit();
    }

    validate(): boolean {
        const { embeddedType, targetId, configName, itemId, queryId, detailsList, allowEventCreation, sessionId } =
            this.config;

        let message: string;
        if (!embeddedType) message = "Please choose an embedded type.";
        else if (!targetId)
            message = "Please enter the id for the element on your website that will contain the embedding.";
        else if (!configName) message = "Please enter a config name.";
        else if (this.mode === "item" && !itemId) message = "Please select an item.";
        else if (this.mode === "query" && !queryId) message = "Please select a search.";
        else if (
            !sessionId &&
            (this.embeddedTypeData.canLogin || allowEventCreation) &&
            !this.requireLogin &&
            embeddedType !== "details-list"
        )
            message = "Please select a session contact and generate a session ID.";
        else if (embeddedType === "details-list" && !detailsList.grouping)
            message = "Please choose a details list grouping.";
        else if (embeddedType === "details-list" && !this.elements.filter((element: any) => element.included).length)
            message = "Please choose at least one element to show in the details list.";

        if (message) alert(message);
        return !message;
    }

    startLoading(name: keyof Loaders) {
        this.loading[name] = true;
        this.changeDetector.detectChanges();
    }

    stopLoading(name: keyof Loaders) {
        this.loading[name] = false;
        this.changeDetector.detectChanges();
    }

    onSearchSelection(data: any) {
        this.config.queryId = data.itemId;
        this.config.queryName = data.itemName;
    }

    async clearSessionContact() {
        await this.deleteSession();
        this.sessionContact = null;
    }

    onRequireLoginChange(value: boolean) {
        if (value) return this.clearSessionContact();
    }
}

type Loaders = {
    session?: boolean;
    save?: boolean;
};
