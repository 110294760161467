//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { EmailDefaultsComponent } from "./email.defaults.component";
import { S25ToggleButtonModule } from "../../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    declarations: [EmailDefaultsComponent],
    imports: [CommonModule, FormsModule, S25ToggleButtonModule],
    providers: [EmailDefaultsComponent],
})
export class EmailDefaultsModule {
    constructor() {}
}
