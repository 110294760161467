import { S25Util } from "../../../../util/s25-util";

const properties = {
    /* 0 */ fontColor: {
        name: "fontColor",
        prop: "color",
        display: "Font Color",
        help: "Examples: black | #B22222 | rgb(95,158,160)",
    },
    /* 1 */ fontFamily: {
        name: "fontFamily",
        prop: "font-family",
        display: "Font Family",
        help: "Font names that contain whitespace must have quotes. Examples: Arial, sans-serif | 'Times New Roman', serif ",
    },
    /* 2 */ fontSize: {
        name: "fontSize",
        prop: "font-size",
        display: "Font Size",
        help: "Examples: 16px | 1em",
    },
    /* 3 */ fontWeight: {
        name: "fontWeight",
        prop: "font-weight",
        display: "Font Weight",
        help: "Examples: normal | bold",
        dropdown: true,
        options: [
            { display: "Normal", value: "400" },
            { display: "Medium", value: "500" },
            { display: "Bold", value: "700" },
        ],
    },
    /* 4 */ backgroundColor: {
        name: "backgroundColor",
        prop: "background-color",
        display: "Background Color",
        help: "Examples: white | #FFFAFA | rgb(255,228,225)",
    },
    /* 4 */ columnHeaderBackground: {
        name: "backgroundColor",
        prop: "--column-header-bg",
        display: "Background Color",
        help: "Examples: white | #FFFAFA | rgb(255,228,225)",
    },
    /* 5 */ padding: {
        name: "padding",
        prop: "padding",
        display: "Padding",
        help: "One value will set the padding on all sides to that value; Example: 10px. Four values will set the padding on top, right, bottom, and left sides (in that order); Example: 10px 5px 20px 5px",
    },
    /* 6 */ bottomBorder: {
        name: "bottomBorder",
        prop: "border-bottom",
        display: "Bottom Border",
        help: "Examples: 1px solid grey | 2px dotted #556B2F | none",
    },
    /* 7 */ textAlign: {
        name: "textAlign",
        prop: "text-align",
        display: "Text Align",
        help: "Examples: right | left | center",
    },
    /* 8 */ borderColor: {
        name: "borderColor",
        prop: "border-color",
        display: "Border Color",
        help: "Examples: black | transparent | #556B2F",
    },
    /* 9 */ color: {
        name: "color",
        prop: "color",
        display: "Color",
        help: "Examples: black | #B22222 | rgb(95,158,160)",
    },
    /* 10 */ backgroundHoverColor: {
        name: "backgroundHoverColor",
        prop: "background-color",
        display: "Background Hover Color",
        help: "Examples: white | #FFFAFA | rgb(255,228,225)",
    },
    /* 11 */ fontHoverColor: {
        name: "fontHoverColor",
        prop: "color",
        display: "Font Hover Color",
        help: "Examples: black | #B22222 | rgb(95,158,160)",
    },
    /* 12 */ url: {
        name: "url",
        prop: "url",
        display: "Source URL",
        help: 'Copy and paste the link to the font (the href value) without quotes. Example: https://fonts.googleapis.com/css2?family=Hahmlet:wght@400;500&display=swap"',
    },
    /* 13 */ alternateBackgroundColor: {
        name: "alternateBackgroundColor",
        prop: "background-color",
        display: "Alternate Background Color",
        help: "Examples: white | #FFFAFA | rgb(255,228,225)",
    },
    /* 14 */ alternateFontColor: {
        name: "alternateFontColor",
        prop: "color",
        display: "Alternate Font Color",
        help: "Examples: black | #B22222 | rgb(95,158,160)",
    },
};

// Event Details List
const detailsListElements = [
    {
        name: "body",
        display: "Body",
        help: "These styles will be applied to the body element of the page.",
        important: true,
        class: "body, .ngOptSubContainer label",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.backgroundColor),
            S25Util.clone(properties.padding),
        ],
    },
    {
        name: "event",
        display: "Event",
        help: "These styles will apply to all events in the list. This is a good place to define a common font that will be used for all text besides the group date, event name, and labels, which you can specify below.",
        class: ".edl-event",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
            S25Util.clone(properties.backgroundColor),
            S25Util.clone(properties.padding),
            S25Util.clone(properties.bottomBorder),
        ],
    },
    {
        name: "groupName",
        display: "Group Name",
        help: "The group name is the date header for each grouping of events that falls under that date (Example: Friday, June 11th).",
        class: ".edl-group-name",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
            S25Util.clone(properties.backgroundColor),
            S25Util.clone(properties.padding),
        ],
    },
    {
        name: "eventName",
        display: "Event Name",
        class: ".edl-event-name",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
    },
    {
        name: "labels",
        display: "Labels",
        help: "Styles for the labels of the elements in your events.",
        class: ".edl-label",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
    },
];

// Calendar
const calendarElements = [
    {
        name: "body",
        display: "Body",
        help: "These styles will be applied to the body element of the page.",
        important: true, // set to true if !important needs to be added at end of style declaration
        class: "body, .s25-ng label, .s25-ng .ngOptDateTitle:hover, .s25-ng .ngOptDateTitle, .ngOptSubContainer .select2-container a.select2-choice, .ngOptSubContainer div.c-sectionHead",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
        ],
        secondaryClass: "body#s25", //add secondary styles so background color will only be applied to body and not other classes above
        secondaryStyles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.padding)],
    },
    {
        name: "weekdayHeaders",
        display: "Weekday Headers",
        help: "The days of the week above the calendar.",
        important: true,
        class: ".MonthCalendarTable thead th",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
    },
    {
        name: "calendarHeaders",
        display: "Calendar Headers",
        help: "The headers at the top of each calendar day with the date.",
        important: true,
        class: ".CalendarDayHeader",
        styles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.textAlign)],
        secondaryClass: ".CalendarDayHeader a",
        secondaryStyles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
    },
    {
        name: "monthNameHeader",
        display: "Month Name Header",
        help: "The header at the start of each week or month that includes the month name.",
        important: true,
        class: ".caltable td .CalendarDayHeader.MonthName",
        styles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.textAlign)],
        secondaryClass: ".CalendarDayHeader.MonthName a", // secondary class is a class that will be nested inside of the primary element class
        secondaryStyles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
    },
    {
        name: "calendarCells",
        display: "Calendar Cells",
        important: true,
        class: ".s25-ng .CalendarCell",
        styles: [S25Util.clone(properties.backgroundColor)],
        secondaryClass: "#s25.s25-ng .CalendarCellToday",
        secondaryStyles: [
            {
                name: "backgroundColor",
                prop: "background-color",
                display: "Today's Cell Background Color",
                help: "Examples: white | #FFFAFA | rgb(255,228,225)",
            },
        ],
    },
    {
        name: "calendarEvents",
        display: "Calendar Events",
        important: true,
        class: ".ngCalendarDayEventItem .eventItem a",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
        secondaryClass: "#s25 .ngCalendarDayEventItem .eventItem a:hover",
        secondaryStyles: [S25Util.clone(properties.backgroundHoverColor)],
    },
    {
        name: "calendarItems",
        display: "Other Calendar Items",
        help: "These styles will apply to locations, organizations, and resources on the calendar.",
        important: true,
        class: ".ngCalendarDayEventItem .ngSubjectItem a",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.fontWeight),
        ],
        secondaryClass: "#s25 .ngCalendarDayEventItem .ngSubjectItem a:hover",
        secondaryStyles: [S25Util.clone(properties.backgroundHoverColor)],
    },
    {
        name: "calendarControls",
        display: "Calendar Controls",
        important: true,
        class: ".s25-ng .btn.btn-default, .ngOptSubContainer select.cn-form__control, .ngOptSubContainer .c-label--checkbox::before, .s25-ng .ngOptSubContainer .btn.btn-flat:hover, .ngOptSubContainer div.ui-select-container, .ngOptSubContainer .select2-container a.select2-choice, .ngOptSubContainer div.c-sectionHead",
        styles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.borderColor)],
        secondaryClass: ".s25-ng .btn.btn-flat, .s25-ng .btn.btn-default, .ngOptSubContainer select.ngListPageDropdown",
        secondaryStyles: [
            {
                name: "iconColor",
                prop: "color",
                display: "Icon Color",
                help: "Examples: black | #B22222 | rgb(95,158,160)",
            },
        ],
    },
];

//Event Form
const eventFormElements = [
    {
        name: "body",
        display: "Body",
        help: "These styles will be applied to the body element of the page.",
        important: true,
        class: "body, s25-info-message div.eventFormMessage .info-message",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
        ],
        secondaryClass: "body#s25",
        secondaryStyles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.padding)],
    },
    {
        name: "labels",
        display: "Labels",
        help: "Styles for the labels of input fields on the form.",
        important: true,
        class: "s25-event-form-item label",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
        ],
    },
    {
        name: "inputs",
        display: "Input Fields",
        important: true,
        class: "s25-event-form-item input.c-input, s25-event-form-item input.s25-input, s25-event-form-item input.ngTimepickerInput, s25-event-form-item .ngDropdownPaginated .ui-select-container, #s25 s25-event-form-item .ngDropdownPaginated a, s25-event-creation-form select.cn-form__control, s25-event-form-item .sQLQueryInput--wrapper, .c-roseForm--wrapper s25-event-form-item textarea, .rose-form--additional-time--summary, s25-event-creation-form s25-dropdown-paginated .ui-select-container a.s25-item .s25-item-holder, s25-event-creation-form select.c-selectInput, s25-event-creation-form .c-label--checkbox::before, s25-event-creation-form .ui-select-dropdown, s25-event-creation-form .ngDropdownScrollTopEl",
        styles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.fontColor)],
    },
    {
        name: "linksIcons",
        display: "Links and Icons",
        important: true,
        class: "s25-event-creation-form s25-info-message .info-message-toggle--button svg, s25-event-creation-form .c-textButton, #s25 s25-event-creation-form .b-listview a.s25-object, s25-event-creation-form .ngAnchor.ngAnchor, .s25-ng s25-event-creation-form a",
        styles: [S25Util.clone(properties.color)],
        secondaryClass:
            "s25-event-creation-form .c-textButton:hover,  #s25 s25-event-creation-form .b-listview .b-listview-tr .s25-object:hover *,  .s25-ng s25-event-creation-form a:hover, .s25-ng s25-event-creation-form .b-listview-th:not(.b-listview-th-nosort):hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor)],
    },
    {
        name: "accentBackground",
        display: "Accent Background Color",
        help: "This will be the background color for the side navigation, the headers of the collapsable sections, and the alternating rows of the table.",
        important: true,
        class: "s25-event-creation-form .c-sectionHead--roseSearchCollapse, .ngEventFormNav ul, s25-event-creation-form .b-listview .b-listview-th, s25-event-creation-form .b-listview-tr.ngListRow.ngTableRow:nth-child(even)",
        styles: [S25Util.clone(properties.backgroundColor)],
    },
    {
        name: "primaryButtons",
        display: "Primary Buttons",
        important: true,
        class: "s25-event-creation-form button.aw-button--primary, s25-event-creation-form .toggle__radio--2.ngChecked~.toggle__knob::after, #s25 s25-event-creation-form .ngClickToPickGeneric .b-datepicker .b-datepicker-table td.current-day-override>button",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
        hiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ], //props that are hidden from form (prop) and assigned same value as previous prop (sameAs) within the class
        secondaryClass:
            "s25-event-creation-form button.aw-button--primary:hover, s25-event-creation-form .aw-button--primary:not([disabled]):hover, s25-event-creation-form .toggle__radio--2.ngChecked~.toggle__knob,  #s25 s25-event-creation-form .ngClickToPickGeneric .b-datepicker .b-datepicker-table td.current-day-override>button:hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor), S25Util.clone(properties.backgroundHoverColor)],
        secondaryHiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ], //same as hiddenProps but for secondary class
    },
    {
        name: "outlineButtons",
        display: "Outline Buttons",
        important: true,
        class: "s25-event-creation-form button.aw-button.aw-button--outline, s25-event-creation-form .file-btn, #s25 s25-event-form-item s25-event-form-multiselect-item .s25-popup-btn-text",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
        hiddenProps: [{ prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.fontColor) }],
        secondaryClass:
            "s25-event-creation-form button.aw-button.aw-button--outline:hover, s25-event-creation-form .upload-btn-wrapper input[type=file]:hover~.file-btn, #s25 s25-event-form-item s25-event-form-multiselect-item .s25-popup-btn-text:hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor), S25Util.clone(properties.backgroundHoverColor)],
        secondaryHiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.fontColor) },
        ],
    },
];

// Allow Event Creation (Shortened Event Form/Express Form)
const eventCreationArray = [
    {
        name: "labels",
        display: "Labels",
        help: "Styles for the labels of input fields on the form.",
        important: true,
        class: ".s25-ng s25-event-form-item label, .express-scheduling--wrapper label.ngBold",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
        ],
    },
    {
        name: "inputs",
        display: "Input Fields",
        important: true,
        class: "s25-event-form-item input.c-input, s25-event-form-item input.s25-input, s25-event-form-item input.ngTimepickerInput, s25-event-form-item .ngDropdownPaginated .ui-select-container, #s25 s25-event-form-item .ngDropdownPaginated a, s25-event-creation-form select.cn-form__control, s25-event-form-item .sQLQueryInput--wrapper, .c-roseForm--wrapper s25-event-form-item textarea, .rose-form--additional-time--summary, s25-event-creation-form s25-dropdown-paginated .ui-select-container a.s25-item .s25-item-holder, s25-event-creation-form select.c-selectInput, s25-event-creation-form .c-label--checkbox::before, s25-event-creation-form .ui-select-dropdown, s25-event-creation-form .ngDropdownScrollTopEl, .express-scheduling--wrapper input,  .express-scheduling--wrapper div.ui-select-container, .express-scheduling--wrapper div.ui-select-container a.select2-choice, .express-scheduling--wrapper div.rose-form--additional-time--summary, .express-scheduling--wrapper .ui-select-dropdown, .express-scheduling--wrapper .ngDropdownScrollTopEl",
        styles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.fontColor)],
    },
    {
        name: "linksIcons",
        display: "Links and Icons",
        important: true,
        class: "s25-event-creation-form s25-info-message .info-message-toggle--button svg, s25-event-creation-form .c-textButton, #s25 s25-event-creation-form .b-listview a.s25-object, s25-event-creation-form .ngAnchor.ngAnchor, .s25-ng s25-event-creation-form a",
        styles: [S25Util.clone(properties.color)],
        secondaryClass:
            "s25-event-creation-form .c-textButton:hover,  #s25 s25-event-creation-form .b-listview .b-listview-tr .s25-object:hover *,  .s25-ng s25-event-creation-form a:hover, .s25-ng s25-event-creation-form .b-listview-th:not(.b-listview-th-nosort):hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor)],
    },
    {
        name: "accentBackground",
        display: "Accent Background Color",
        help: "This will be the background color for the side navigation, the headers of the collapsable sections, and the alternating rows of the table.",
        important: true,
        class: "s25-event-creation-form .c-sectionHead--roseSearchCollapse, .ngEventFormNav ul, s25-event-creation-form .b-listview .b-listview-th, s25-event-creation-form .b-listview-tr.ngListRow.ngTableRow:nth-child(even)",
        styles: [S25Util.clone(properties.backgroundColor)],
    },
    {
        name: "primaryButtons",
        display: "Primary Buttons",
        important: true,
        class: "s25-event-creation-form button.aw-button--primary, s25-event-creation-form .toggle__radio--2.ngChecked~.toggle__knob::after, #s25 s25-event-creation-form .ngClickToPickGeneric .b-datepicker .b-datepicker-table td.current-day-override>button, .express-scheduling--wrapper button.aw-button--primary",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
        hiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ], //props that are hidden from form (prop) and assigned same value as previous prop (sameAs) within the class
        secondaryClass:
            "s25-event-creation-form button.aw-button--primary:hover, s25-event-creation-form .aw-button--primary:not([disabled]):hover, s25-event-creation-form .toggle__radio--2.ngChecked~.toggle__knob,  #s25 s25-event-creation-form .ngClickToPickGeneric .b-datepicker .b-datepicker-table td.current-day-override>button:hover, .express-scheduling--wrapper button.aw-button--primary:hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor), S25Util.clone(properties.backgroundHoverColor)],
        secondaryHiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ], //same as hiddenProps but for secondary class
    },
    {
        name: "outlineButtons",
        display: "Outline Buttons",
        important: true,
        class: "s25-event-creation-form button.aw-button.aw-button--outline, s25-event-creation-form .file-btn, #s25 s25-event-form-item s25-event-form-multiselect-item .s25-popup-btn-text, .express-scheduling--wrapper button.aw-button.aw-button--outline",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
        hiddenProps: [{ prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.fontColor) }],
        secondaryClass:
            "s25-event-creation-form button.aw-button.aw-button--outline:hover, s25-event-creation-form .upload-btn-wrapper input[type=file]:hover~.file-btn, #s25 s25-event-form-item s25-event-form-multiselect-item .s25-popup-btn-text:hover, .express-scheduling--wrapper button.aw-button.aw-button--outline:hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor), S25Util.clone(properties.backgroundHoverColor)],
        secondaryHiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.fontColor) },
        ],
    },
];

// Express
const expressElements = [
    {
        name: "body",
        display: "Body",
        help: "These styles will be applied to the body element of the page.",
        important: true,
        class: "body",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.backgroundColor),
        ],
    },
    {
        name: "labels",
        display: "Labels",
        help: "Styles for the labels of input fields on the form.",
        important: true,
        class: "#s25 .express-scheduling--wrapper label.ngBold",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
        ],
    },
    {
        name: "inputs",
        display: "Input Fields",
        important: true,
        class: "#s25 .express-scheduling--wrapper input, #s25 .express-scheduling--wrapper div.ui-select-container, #s25 .express-scheduling--wrapper div.ui-select-container a.select2-choice, #s25 .express-scheduling--wrapper div.rose-form--additional-time--summary, #s25 .express-scheduling--wrapper .ui-select-dropdown, #s25 .express-scheduling--wrapper .ngDropdownScrollTopEl",
        styles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.fontColor)],
    },
    {
        name: "primaryButtons",
        display: "Primary Buttons",
        important: true,
        class: "#s25 .express-scheduling--wrapper button.aw-button--primary",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
        hiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ], //make border same as selected  color
        secondaryClass: "#s25 .express-scheduling--wrapper button.aw-button--primary:hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor), S25Util.clone(properties.backgroundHoverColor)],
        secondaryHiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ], //same as hiddenProps but for secondary class
    },
    {
        name: "outlineButtons",
        display: "Outline Buttons",
        important: true,
        class: "#s25 .express-scheduling--wrapper button.aw-button.aw-button--outline",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
        hiddenProps: [{ prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.fontColor) }],
        secondaryClass: "#s25 .express-scheduling--wrapper button.aw-button.aw-button--outline:hover",
        secondaryStyles: [S25Util.clone(properties.fontHoverColor), S25Util.clone(properties.backgroundHoverColor)],
        secondaryHiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.fontColor) },
        ],
    },
];

// Availability Daily
const availabilityDailyElements = [
    {
        name: "body",
        display: "Body",
        help: "These styles will be applied to the body element of the page.",
        important: true,
        class: "body, .ngAvailability div.s25-item-name, s25-ng-availability-grid .s25-item-name, .s25-ng .ngOptDateTitle:hover, .s25-ng .ngOptDateTitle, label.c-label--checkbox, #s25 .ngOptSubContainer select.ngOptModeDropdown, .ngOptSubContainer .btn.btn-default, .ngOptSubContainer label, .ngOptSubContainer select.ngListPageDropdown, .ngAvailCellText, .s25-ng .s25-multiselect-popup-container, .s25-ng .s25-multiselect-popup-container .s25-header", //, .s25-ng .s25-multiselect-popup-container .s25-multiselect-item-text-col
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.fontFamily)],
        secondaryClass: "body#s25",
        secondaryStyles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.padding)],
    },
    {
        name: "gridHeader",
        display: "Availability Grid Header",
        help: "These styles will be applied to the number cells at the top of the availability grid.",
        important: true,
        class: ".ngAvailability .ngGridHeaderRow .ngGridHeaderCell, s25-ng-availability-grid .header",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.backgroundColor),
        ],
        secondaryClass: ".ngAvailability .ngGridHeaderRow .ngAvailPM, s25-ng-availability-grid .header.pm",
        secondaryStyles: [S25Util.clone(properties.alternateBackgroundColor)], //S25Util.clone(properties.alternateBackgroundColor)
    },
    {
        name: "gridRows",
        display: "Grid Rows",
        important: true,
        class: ".ngAvailability .ngDefColor, s25-ng-availability-grid .availRow", //, .ngAvailability div.s25-item-name
        styles: [S25Util.clone(properties.backgroundColor)],
        secondaryClass: ".ngAvailability .ngAltColor, s25-ng-availability-grid .availRow:nth-child(2n+1)",
        secondaryStyles: [S25Util.clone(properties.alternateBackgroundColor)], //S25Util.clone(properties.alternateBackgroundColor)
    },
    {
        name: "availabilityControls",
        display: "Grid Controls",
        important: true,
        class: ".s25-ng .btn.btn-default, .ngOptSubContainer select.cn-form__control, .ngOptSubContainer .c-label--checkbox::before, .s25-ng .ngOptSubContainer .btn.btn-flat:hover, .ngOptSubContainer div.ui-select-container, .ngOptSubContainer .select2-container a.select2-choice, .ngOptSubContainer div.c-sectionHead, .ngOptSubContainer a.aw-button--outline:hover, .ngOptSubContainer a.aw-button--outline, .s25-ng .s25-multiselect-popup-container, .s25-ng .s25-multiselect-popup-container .s25-header",
        styles: [S25Util.clone(properties.backgroundColor)],
        hiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ],
        secondaryClass:
            ".s25-ng .btn.btn-flat, .ngOptSubContainer select.ngOptModeDropdown, .ngOptSubContainer a.aw-button--outline",
        secondaryStyles: [
            {
                name: "iconColor",
                prop: "color",
                display: "Icon Color",
                help: "Examples: black | #B22222 | rgb(95,158,160)",
            },
        ],
    },
];

// Availability Weekly
const availabilityWeeklyElements = [
    {
        name: "body",
        display: "Body",
        help: "These styles will be applied to the body element of the page.",
        important: true,
        class: "body, .ngAvailability div.s25-item-name, .s25-ng .ngOptDateTitle:hover, .s25-ng .ngOptDateTitle, label.c-label--checkbox, #s25 .ngOptSubContainer select.ngOptModeDropdown, .ngOptSubContainer .btn.btn-default, .ngOptSubContainer label, .ngOptSubContainer select.ngListPageDropdown,  .s25-ng .s25-multiselect-popup-container, .s25-ng .s25-multiselect-popup-container .s25-header",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.fontFamily)],
        secondaryClass: "body#s25",
        secondaryStyles: [S25Util.clone(properties.backgroundColor), S25Util.clone(properties.padding)],
    },
    {
        name: "columnHeader",
        display: "Weekday Column Header",
        help: "These styles will be applied to the weekday cells at the top of the availability grid.",
        important: true,
        class: "s25-ng-avail-weekly .grid--column-headers",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.columnHeaderBackground),
        ],
        secondaryClass: "s25-ng-avail-weekly .column-header.date",
        secondaryStyles: [S25Util.clone(properties.alternateFontColor)],
    },
    {
        name: "rowHeader",
        display: "Time Row Header",
        help: "These styles will be applied to the time cells to the left of the availability grid.",
        important: true,
        class: "s25-ng-avail-weekly .row-header",
        styles: [
            S25Util.clone(properties.fontColor),
            S25Util.clone(properties.fontFamily),
            S25Util.clone(properties.fontSize),
            S25Util.clone(properties.backgroundColor),
        ],
        secondaryClass: "s25-ng-avail-weekly .row-header.afternoon",
        secondaryStyles: [S25Util.clone(properties.alternateBackgroundColor)],
    },
    {
        name: "multiLocationHeader",
        display: "Multi Location Header",
        help: "If you are displaying multiple locations, this header will appear above the availability grid.",
        class: "#s25 s25-ng-multi-avail-weekly .location :where(.header, .s25-item-link)",
        styles: [S25Util.clone(properties.fontColor), S25Util.clone(properties.backgroundColor)],
    },
    {
        name: "availabilityControls",
        display: "Grid Controls",
        important: true,
        class: "s25-ng-avail-weekly-options .c-label--checkbox::before, s25-ng-avail-weekly-options select.cn-form__control, s25-ng-avail-weekly-options .aw-button--outline",
        styles: [S25Util.clone(properties.backgroundColor)],
        hiddenProps: [
            { prop: S25Util.clone(properties.borderColor), sameAs: S25Util.clone(properties.backgroundColor) },
        ],
        secondaryClass:
            "s25-ng-avail-weekly-options s25-ng-icon, s25-ng-avail-weekly-options select.cn-form__control, s25-ng-avail-weekly-options .aw-button--outline",
        secondaryStyles: [
            {
                name: "iconColor",
                prop: "color",
                display: "Icon Color",
                help: "Examples: black | #B22222 | rgb(95,158,160)",
            },
        ],
    },
];

export const S25EmbeddingStylesFormConst = {
    properties,
    detailsListElements,
    calendarElements,
    eventFormElements,
    eventCreationArray,
    expressElements,
    availabilityDailyElements,
    availabilityWeeklyElements,
};
