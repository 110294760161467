//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";
import { ContactService } from "../../../services/contact.service";

@TypeManagerDecorator("system-email-defaults")
@Component({
    selector: "system-email-defaults",
    template: `
        <h2 class="c-margin-bottom--single">Email Defaults</h2>
        <div class="c-margin-bottom--single">
            <label for="email" class="ngBold c-margin-bottom--quarter"> Contact Admin Email Address </label>
            <p class="ngFinePrint c-margin-bottom--half">
                Required. Email address used for the Contact Admin link in the About section of the More menu.
            </p>
            <input
                type="text"
                id="email"
                [(ngModel)]="this.email"
                class="c-input"
                (ngModelChange)="this.validEmail()"
            />
            @if (!this.isValidEmail) {
                <p class="ngRed">Please enter a valid email address.</p>
            }
        </div>

        <div class="c-margin-bottom--single">
            <label for="show" class="ngBold c-margin-bottom--quarter"> Email Password Reminder </label>
            <p class="ngFinePrint c-margin-bottom-half">
                Setting that determines whether the password reminder link appears in 25Live. If single sign-on is not
                implemented at your site, the non-sign-in user will only receive an email with their requested password
                reminder if the 25Live Viewer Seat has functional security for Tasks, Reports and Email > Send Email set
                to "Can Send".
            </p>
            <s25-toggle-button
                (modelValueChange)="this.onChange($event)"
                [modelValue]="this.show"
                [trueLabel]="trueLabel"
                [falseLabel]="falseLabel"
            ></s25-toggle-button>
        </div>

        <div class="c-margin-bottom--single">
            <label for="subDomain" class="ngBold c-margin-bottom--quarter"> Email Subdomain</label>
            <p class="ngFinePrint c-margin-bottom--half">
                Add a subdomain to emails sent from 25live for all users. Entering a subdomain here will also set a
                property for 'reply to'.
            </p>
            <input
                type="text"
                id="subDomain"
                [(ngModel)]="this.subDomain"
                class="c-input"
                (ngModelChange)="sampleEmailFrom()"
            />
            <p>
                Example from email: <b>{{ this.sampleWithSubDomain }}</b>
            </p>
        </div>

        <div class="c-margin-bottom-single">
            <label for="globalEmail" class="ngBold c-margin-bottom--quarter">Global From Email Address</label>
            <p class="ngFinePrint c-margin-bottom--half">
                All email from 25live will be sent from this email address and reply to the user's configured work
                email.
            </p>
            <input
                type="text"
                id="globalEmail"
                [(ngModel)]="globalFrom"
                class="c-input"
                (ngModelChange)="validGlobalEmail(); sampleEmailFrom()"
            />
            @if (this.globalMsg) {
                <p class="ngRed">{{ globalMsg }}</p>
            }
        </div>

        <div class="c-displayBlock c-padding-bottom--double">
            <button
                class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                (click)="save()"
                [disabled]="!this.isValidEmail || this.globalMsg"
            >
                {{ this.loading ? "Saving...." : "Save" }}
            </button>
            <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailDefaultsComponent implements OnInit {
    @Input() falseLabel: string = "Hide reminder link";
    @Input() trueLabel: string = "Show reminder link";
    @Output() modelValueChange = new EventEmitter();
    email: string = "";
    show: boolean = false;
    showRemind: string = "";
    subDomain: string = "";
    // replyTo: boolean = false;

    loading: boolean = false;
    isValidEmail: boolean = true;
    initEmail: string = "";
    initShowRemind: string = "";
    initSubDomain: string = "";
    initReplyTo: boolean = false;

    initGlobalFrom: string = "";
    globalFrom: string = "";
    globalMsg: string = "";

    userEmail: string = "";
    sampleWithSubDomain: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    onChange($event: any) {
        this.modelValueChange.emit(this.show);
        this.show = $event;
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(
                [
                    "config_feedback_email",
                    "config_email_password",
                    "EmailSubdomain",
                    "EmailReplyTo",
                    "EmailFromAddress",
                ],
                "S",
            ),
            userEmail: ContactService.getCurrentEmail(),
        }).then((resp) => {
            this.email = resp.prefs.config_feedback_email.value;
            resp.prefs.config_email_password.value === "show" ? (this.show = true) : (this.show = false);
            this.initEmail = resp.prefs.config_feedback_email.value;
            this.initShowRemind = resp.prefs.config_email_password.value;
            this.initSubDomain = resp.prefs.EmailSubdomain?.value;
            this.subDomain = resp.prefs.EmailSubdomain?.value;
            this.initReplyTo = !!resp.prefs.EmailReplyTo?.value;

            this.initGlobalFrom = resp.prefs.EmailFromAddress?.value;
            this.globalFrom = resp.prefs.EmailFromAddress?.value;

            this.userEmail = resp.userEmail;

            this.validEmail();
            this.sampleEmailFrom();
            this.cd.detectChanges();
        });
    }

    validEmail() {
        this.isValidEmail = S25Util.isValidEmail(this.email);
        this.cd.detectChanges();
    }

    validGlobalEmail() {
        this.globalMsg = "";
        if (this.globalFrom.includes("collegenet.com")) {
            this.globalMsg = "Global email cannot contain 'collegenet.com'";
        } else if (this.globalFrom && !S25Util.isValidEmail(this.globalFrom)) {
            this.globalMsg = "Please enter a valid email address.";
        }
        this.cd.detectChanges();
    }

    sampleEmailFrom() {
        this.sampleWithSubDomain = this.globalFrom
            ? this.applySubDomain(this.globalFrom)
            : this.applySubDomain(this.userEmail);
        this.cd.detectChanges();
    }

    /*
    Applies subdomain to the provided email address
     */
    applySubDomain(email: string) {
        return this.subDomain ? email.replace("@", "@" + this.subDomain + ".") : email;
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();
        this.show ? (this.showRemind = "show") : (this.showRemind = "hide");

        let promiseArr = [];
        this.initEmail !== this.email &&
            promiseArr.push(PreferenceService.setPreference("config_feedback_email", this.email, "S"));
        this.initShowRemind !== this.showRemind &&
            promiseArr.push(PreferenceService.setPreference("config_email_password", this.showRemind, "S"));
        if (this.initSubDomain !== this.subDomain) {
            promiseArr.push(PreferenceService.setPreference("EmailSubdomain", this.subDomain, "S"));
            promiseArr.push(PreferenceService.setPreference("EmailReplyTo", this.subDomain ? 1 : 0, "S"));
        }
        this.initGlobalFrom !== this.globalFrom &&
            promiseArr.push(
                PreferenceService.setPreference("EmailFromAddress", this.globalFrom, "S").then(() => {
                    this.initGlobalFrom = this.globalFrom;
                }),
            );

        if (promiseArr) {
            return S25Util.all(promiseArr).then(() => {
                this.loading = false;
                this.cd.detectChanges();
            });
        }
    }

    cancel() {
        this.getPrefs();
    }
}
