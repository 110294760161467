import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { GenericTableButtonComponent } from "../../../s25-table/generics/generic.table.button.component";
import { EmbeddedConfigListItem, EmbeddedConfigService } from "../../../s25-embedding-config/embedded.config.service";
import { Bind } from "../../../../decorators/bind.decorator";
import { ModalService } from "../../../modal/modal.service";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25EmbeddingUtil } from "./s25.embedding.util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-embedding-list")
@Component({
    selector: "s25-ng-embedding-list",
    template: `
        <div class="system-settings--embedding">
            <h3 class="c-margin-bottom--single">Embedding Series25 Content in Your Website</h3>

            <div class="c-margin-bottom--single">
                <p>
                    <strong>First step:</strong> To add one or more embedded items to your page, you will need to add
                    the following script to the &lt;head&gt; tag of your page once:
                </p>
                <pre>
&lt;script src="https://25live.collegenet.com/pro/embedding-client-code/embedded25.js"&gt;&lt;/script&gt;
</pre
                >
            </div>
            <p class="c-margin-bottom--single">
                <strong>Second step:</strong> Select or create a new embedded content configuration (config). You can
                save configs to access again later.
            </p>
            <button class="aw-button aw-button--primary" (click)="onCreate()">Add Config</button>
            <s25-ng-table [dataSource]="tableConfig" [columnSortable]="true" [hasFilter]="true"></s25-ng-table>
        </div>
    `,
    styles: `
        pre {
            clear: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EmbeddingListComponent implements OnInit {
    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;

    tableConfig: Table.DataSource;

    ngOnInit() {
        this.tableConfig = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "name",
                    header: "Name",
                },
                {
                    id: "type",
                    header: "Type",
                },
                {
                    id: "target",
                    header: "Target ID",
                },
                GenericTableButtonComponent.Column("Preview", this.onPreview, "outline"),
                GenericTableButtonComponent.Column("Copy", this.onCopy, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEdit, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDelete, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        const configs = await EmbeddedConfigService.getConfigs();
        return {
            rows: configs.map(this.mapToRow),
        };
    }

    mapToRow(config: EmbeddedConfigListItem): Table.Row {
        return {
            id: config.configToken,
            name: String(config.configName),
            cells: {
                name: { text: config.configName },
                type: { text: S25EmbeddingUtil.types[config.embeddedType].name },
                target: { text: config.targetId },
            },
        };
    }

    onCreate() {
        ModalService.modal("edit-embedded-config", {
            mode: "create",
            title: `Create Embedded Config`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    async onPreview(row: Table.Row) {
        const config = await EmbeddedConfigService.getConfig(row.id as string);
        const link = S25EmbeddingUtil.getPreviewLink(config);
        window.open(link, "_blank");
    }

    @Bind
    onCopy(row: Table.Row) {
        ModalService.modal("edit-embedded-config", {
            config: row.id,
            mode: "copy",
            title: `Copy Config: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    onEdit(row: Table.Row) {
        ModalService.modal("edit-embedded-config", {
            config: row.id,
            title: `Edit Config: ${row.name}`,
            onSave: this.tableComponent.refresh,
        });
    }

    @Bind
    async onDelete(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this embedding configuration?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no

        await EmbeddedConfigService.deleteConfig(row.id as string);
        return this.tableComponent.refresh();
    }
}
