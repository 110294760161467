import { NgModule } from "@angular/core";
import { S25EmbeddingListComponent } from "./s25.embedding.list.component";
import { S25HelpModule } from "../../../s25-help/s25.help.module";
import { S25TableModule } from "../../../s25-table/s25.table.module";
import { S25EmbeddingConfigComponent } from "./s25.embedding.config.component";
import { S25InfoMessageModule } from "../../../s25-info-message/s25.info.message.module";
import { CommonModule } from "@angular/common";
import { S25LoadingInlineModule } from "../../../s25-loading/s25.loading.inline.module";
import { FormsModule } from "@angular/forms";
import { S25RadioModule } from "../../../s25-radio/s25.radio.module";
import { S25CheckboxModule } from "../../../s25-checkbox/s25.checkbox.module";
import { S25ToggleButtonModule } from "../../../s25-toggle-button/s25.toggle.button.module";
import { S25MultiQueryModule } from "../../../s25-multi-query/s25.multi.query.module";
import { S25DropdownPaginatedModule } from "../../../s25-dropdown/s25.dropdown.paginated.module";
import { S25SimpleCollapseModule } from "../../../s25-simple-collapse/s25.simple.collapse.module";
import { S25DndSortableModule } from "../../../s25-dnd-sortable/s25.dnd.sortable.module";
import { S25EmbeddingStylesComponent } from "./s25.embedding.styles.component";
import { S25TriggerRerenderModule } from "../../../s25-trigger-rerender/s25.trigger.rerender.module";

@NgModule({
    imports: [
        S25HelpModule,
        S25TableModule,
        S25InfoMessageModule,
        CommonModule,
        S25LoadingInlineModule,
        FormsModule,
        S25RadioModule,
        S25CheckboxModule,
        S25ToggleButtonModule,
        S25MultiQueryModule,
        S25DropdownPaginatedModule,
        S25SimpleCollapseModule,
        S25DndSortableModule,
        S25TriggerRerenderModule,
    ],
    exports: [S25EmbeddingListComponent, S25EmbeddingConfigComponent, S25EmbeddingStylesComponent],
    declarations: [S25EmbeddingListComponent, S25EmbeddingConfigComponent, S25EmbeddingStylesComponent],
    providers: [S25EmbeddingListComponent, S25EmbeddingConfigComponent, S25EmbeddingStylesComponent],
})
export class S25EmbeddingModule {}
